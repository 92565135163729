const QUERY_TRANSACTION_GIFT = 'transaction_gift';

export default async function({ query, redirect, $axios }) {
    if (QUERY_TRANSACTION_GIFT in query) {
        const transaction = query[QUERY_TRANSACTION_GIFT];

        await $axios.get(`/v2.0/gift/${transaction}`)
            .catch(function(error) {
                if (error) {
                    let redirectUrl = '/';

                    if ('failed' in query) {
                        redirectUrl += query.failed;
                    }

                    redirect(redirectUrl);
                }
            });
    }
}
